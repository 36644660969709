import React from "react"
import { connect, useSelector } from "react-redux"
import { PrismicRichText } from "@prismicio/react";

import { RegulationsOfficesQuery } from "../../prismic/staticQueries"
import Layout from "../../layout"
import { Subscription, BreadCrumbBanner } from "../../components/common"
import PaymentSlider from "./PaymentSlider";
// import { IMAGES } from '../../constants';

const RegulationsOffices = () => {
  const language = useSelector(state => state.language)
  const regulationsPageData = RegulationsOfficesQuery(language)
  const header = regulationsPageData.description_content1[0];
  const body = regulationsPageData.description_content1.slice(1);
  
  return (
    <>
    
      <Layout>
        <BreadCrumbBanner {...regulationsPageData} heading={''}/>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="mt-5">
                  <div className="divider divider-simple text-center">
                    <h3>{regulationsPageData.page_heading.text}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container py-5">
            <div className="row shadowed-container p-0">
              {body.map((item, index)=>
                <React.Fragment key={index}>
                <div className="col-lg-6 p-0 pb-5 grey-dient">
                  {/* <h4 className="group-head">{regulationsPageData.page_heading}</h4> */}
                  <h5 className="group-head">
                      <img src={item.description_media.url} style={{width: '1.5rem', marginRight: '1rem'}} alt={'Desc'}/>
                        {item.description_heading && item.description_heading.length ?item.description_heading.text : ''}
                  </h5>
                  <div className="px-5 has-seperator">
                  <PrismicRichText field={item.description_content.richText} />
                  </div>
                </div>

                </React.Fragment>
              )}

            </div>
          </div>
          <section>
            <div className={'container regulations '}>
              <div className={"row block-"}>
                <div className="col-lg-12 all-p-without-margin-bottom">
                <h4>{header.description_heading && header.description_heading.length ?header.description_heading.text : ''}</h4>
                  <PrismicRichText filed={header.description_content.richText} />
                </div>
              </div>
            </div>
          </section>
          
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(RegulationsOffices)
